import Sortable from "sortablejs";

export const dataTableMixin = {
  data() {
    return {
      isPageLoading: true,
      items: [],
      totalItems: 100,
      searchQuery: "",
      filters: {},
      selected: [],
      options: {
        descending: true,
        page: 1,
        itemsPerPage: 30,
        totalItems: 100
      },
      rowsPerPageItems: [10, 20, 30, 40, 50],
      method: null,
      bulkDeleteMethod: null,
      bulkUpdateMethod: null,
      reorderMethod: null
    };
  },
  computed: {
    params() {
      return {
        ...this.options,
        ...this.filters,
        query: this.searchQuery
      };
    }
  },
  methods: {
    doSearch() {
      if (this.options.page == 1) {
        this.getDataFromApi();
      } else {
        this.options.page = 1;
      }
    },
    doClear() {
      Object.keys(this.filters).forEach(f => {
        this.filters[f] = null;
      });
      this.searchQuery = "";
      if (this.options.page == 1) {
        this.getDataFromApi();
      } else {
        this.options.page = 1;
      }
    },
    getColor(active) {
      if (active == false || active == 0) return "red";
      else return "primary";
    },
    textIsActive(active) {
      if (active == false || active == 0) return "Неактивен";
      else return "Активен";
    },
    iconIsActive(active) {
      if (active == false || active == 0) return "mdi-eye-off";
      else return "mdi-eye";
    },
    getDataFromApi() {
      //copy current params to modify
      let params = this.params;

      params.length = params.itemsPerPage; //set how many records to fecth per page
      params.start =
        params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset

      const apiParams = this.$url.transformParams(params);
      this.$store
        .dispatch(this.method, apiParams)
        .then(({ data }) => {
          this.$nextTick(() => {
            this.items = data.data;
            this.totalItems = data.totalItems;

            if (this.isPageLoading == true) {
              this.isPageLoading = false;
            }
          });
        })
        .catch(() => {
          this.$notify({
            group: "notify",
            type: "error",
            text: this.$t("NOTIFY.DEFAULT_ERROR")
          });
        });
    },
    handleBulkDelete() {
      let message = this.$t("BUTTON.PLEASE_CONFIRM");
      this.$dialog
        .confirm(message)
        .then(() => {
          this.$store
            .dispatch(
              this.bulkDeleteMethod,
              this.selected.map(x => x.id)
            )
            .then(data => {
              this.$notify({
                group: "notify",
                type: "success",
                title: "<i class='flaticon2-checkmark'></i> Success",
                text: data.message
              });
              this.getDataFromApi();
              this.selected = [];
            })
            .catch(response => {
              this.$notify({
                group: "notify",
                type: "error",
                title: this.$t("ALERTS.ERROR"),
                text: response?.data?.message
              });
            });
        })
        .catch(() => {});
    },
    handleBulkActivate() {
      let message = this.$t("BUTTON.PLEASE_CONFIRM");
      this.$dialog
        .confirm(message)
        .then(() => {
          this.$store
            .dispatch(this.bulkUpdateMethod, {
              ids: this.selected.map(x => x.id),
              data: { active: 1 }
            })
            .then(data => {
              this.$notify({
                group: "notify",
                type: "success",
                title: "<i class='flaticon2-checkmark'></i> Success",
                text: data.message
              });
              this.getDataFromApi();
              this.selected = [];
            })
            .catch(response => {
              this.$notify({
                group: "notify",
                type: "error",
                title: this.$t("ALERTS.ERROR"),
                text: response?.data?.message
              });
            });
        })
        .catch(() => {});
    },
    handleBulkDeactivate() {
      let message = this.$t("BUTTON.PLEASE_CONFIRM");
      this.$dialog
        .confirm(message)
        .then(() => {
          this.$store
            .dispatch(this.bulkUpdateMethod, {
              ids: this.selected.map(x => x.id),
              data: { active: 0 }
            })
            .then(data => {
              this.$notify({
                group: "notify",
                type: "success",
                title: "<i class='flaticon2-checkmark'></i> Success",
                text: data.message
              });
              this.getDataFromApi();
              this.selected = [];
            })
            .catch(response => {
              this.$notify({
                group: "notify",
                type: "error",
                title: this.$t("ALERTS.ERROR"),
                text: response?.data?.message
              });
            });
        })
        .catch(() => {});
    },
    saveOrder(event) {
      const movedItem = this.items.splice(event.oldIndex, 1)[0];
      this.items.splice(event.newIndex, 0, movedItem);
      const newPositions = this.items.map((item, i) => ({
        id: item.id,
        position: i
      }));

      this.$store
        .dispatch(this.reorderMethod, newPositions)
        .then(data => {
          //
        })
        .catch(response => {
          this.$notify({
            group: "notify",
            type: "error",
            title: this.$t("ALERTS.ERROR"),
            text: response?.data?.message
          });
        });
    },
    prepareEditFormDataWithImage() {
      const { image, ...rest } = this.form;
      let formData = new FormData();
      if (typeof image !== "string") {
        formData.append("image", image);
      }
      formData.append("payload", JSON.stringify(rest));
      return formData;
    }
  },
  directives: {
    sortableDataTable: {
      bind(el, binding, vnode) {
        const options = {
          animation: 150,
          onUpdate: function(event) {
            vnode.child.$emit("sorted", event);
          }
        };
        Sortable.create(el.getElementsByTagName("tbody")[0], options);
      }
    }
  }
};
